<template>
  <div class="container-fluid">
    <breadcrumb>
    <img  src="static/img/survey.svg" class="breadcrumb-image" />
      <breadcrumb-item>
        <router-link to="/controlframeworks">Control Frameworks</router-link>
      </breadcrumb-item>
      <breadcrumb-item>
        <router-link :to="{ path: '../../edit' }"
          >Control Framework Categories</router-link
        >
      </breadcrumb-item>
      <breadcrumb-item active> Surveys </breadcrumb-item>
    </breadcrumb>
    <div class="row" style="height: 500px">
      <div class="col-md-12">
        <card v-if="loaded">
          <div>
            <div
              class="card-title"
              style="font-size: 18px; margin-bottom: 20px"
            >
              {{ model.controlFramework }} :
              {{ model.controlFrameworkCategory }}
            </div>

            <fg-input label="Description">
              <textarea
                name="description"
                :disabled="true"
                v-model="model.description"
                class="form-control"
                style="height: 80px"
              ></textarea>
            </fg-input>
          </div>
          <div class="card-footer text-right">
              <button
                type="button"
                @click="close"
                class="btn btn-fill btn-danger btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-times"></i>
                </span>
                Close
              </button>
            </div>
        </card>

        <card v-if="loaded">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <button
                style="height: 40px"
                type="button"
                @click="$router.push(`surveys/new`)"
                class="btn btn-fill btn-info btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-plus"></i>
                </span>
                Add Survey
              </button>
              <fg-input
                type="search"
                class="mb-3"
                style="width: 200px"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
                v-on:input="getAll"
              />
            </div>
            <div class="col-sm-12">
              <el-table
                v-loading="loading"
                style="width: 100%"
                :data="this.surveys"
              >
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>
                <el-table-column
                  :min-width="150"
                  fixed="right"
                  label="Status"
                >
                  <template slot-scope="props">
                    <i
                      v-if="props.row.isActive === 'True'"
                      class="fa fa-check status-active"
                    ></i>
                    <i v-else class="fa fa-times status-inactive"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="100"
                  :width="150"
                  fixed="right"
                  label="Actions"
                >
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Edit'"
                      class="btn-info btn-simple btn-link"
                      @click="$router.push(`surveys/${props.row.id}/edit`)"
                    >
                      <!--@click="$router.push(`/surveys/${props.row.id}/edit`).catch(err => {})"-->
                      <i class="fa fa-edit"></i>
                    </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
              v-on:input="getAll"
            ></l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";
import $ from "jquery";

export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Breadcrumb,
    BreadcrumbItem,
  },
  computed: {
    pagedData() {
      let self = this;
      return self.surveys.slice(self.from, self.to);
    },
    to() {
      let self = this;
      let highBound = self.from + self.pagination.perPage;
      if (self.total < highBound) {
        highBound = self.total;
      }
      return highBound;
    },
    from() {
      let self = this;
      return self.pagination.perPage * (self.pagination.currentPage - 1);
    },
    total() {
      let self = this;
      return self.pagination.total;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      model: {
        controlFramework: "",
        controlFrameworkCategory: "",
        description: "",
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "name",
          label: "Survey",
          minWidth: 300,
        },
        {
          prop: "lastModifiedDate",
          label: "Modified Date",
          minWidth: 150,
        },
        {
          prop: "inUse",
          label: "In Use",
          minWidth: 150,
        },
        {
          prop: "isComplete",
          label: "Completed",
          minWidth: 150,
        }
      ],
      surveys: [],
      loading: true,
      loaded: false,
    };
  },

  created() {
    let self = this;
    self.getAll();
    self.get(self.$route.params.controlFrameworkCategoryId);
  },

  methods: {
    getAll() {
      let self = this;
      self.$store.state.services.surveyService
        .getAll({
          headers: {
            Pagination: `${self.pagination.currentPage},${self.pagination.perPage}`,
          },
          params: {
            Filter: `${self.searchQuery}`,
            ControlFrameworkCategoryId: `${this.$route.params.controlFrameworkCategoryId}`,
          },
        })
        .then((r) => {
          self.surveys = r.data;
          self.headerValues = JSON.parse(r.headers.pagination);
          self.pagination.total = self.headerValues.TotalItems;
          self.pagination.currentPage = self.headerValues.CurrentPage;
          self.pagination.perPage = self.headerValues.ItemsPerPage;
          self.loading = false;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },
    get(controlFrameworkCategoryId) {
      let self = this;
      self.$store.state.services.controlFrameworkCategoryService
        .get(controlFrameworkCategoryId)
        .then((r) => {
          self.model.controlFrameworkCategory = r.data.name;
          self.model.controlFramework = r.data.controlFramework;
          self.model.description = r.data.description;
        })
        .catch((err) => console.log(err));
    },
    close(){
      let self = this;
      self.$router.push("/controlFrameworks/" +
          self.$route.params.controlFrameworkId +
          "/edit");
    }
  },
};
</script>
<style>
</style>


